
import { Vue, Prop, Component, Emit } from "vue-property-decorator"
import { DeleteUploadFileMutation, DeleteUploadFileMutationMutation, UploadFile } from "@/gql"
import Dropzone from "../widgets/input/Dropzone.vue"
import Previewable from "@/components/layout/Previewable.vue"
import Icon from "../widgets/icons/Icon.vue"

@Component({
  components: {
    Dropzone,
    Previewable,
    Icon,
  },
})
export default class DocumentCard extends Vue {
  @Prop({ required: true }) readonly name!: string // Document name, used for file upload and modal name
  @Prop() readonly value?: UploadFile // Adds edit and download functionalities
  @Prop() readonly fileIndex?: number // Index of the file in the array
  @Prop() readonly folder?: string // Add for upload functionality
  @Prop() readonly category?: string // Add for upload functionality
  @Prop({ default: false }) readonly loading?: boolean
  @Prop({ default: false }) readonly deleting?: boolean
  @Prop() readonly unique!: boolean
  @Prop() readonly accept?: string
  @Prop() readonly approvable?: string
  @Prop() readonly editable?: boolean
  @Prop() readonly date?: string
  @Prop({ default: true }) readonly deleteable!: boolean
  @Prop({ default: false }) readonly filled!: boolean

  @Prop({ required: false })
  refetchQueries!: any

  showUploadModal = false
  showDeleteDialog = false
  deletingFile = false
  uploadFiles: UploadFile[] = []
  showPreviewable = false

  get isOutlined() {
    return !this.filled
  }

  @Emit("change")
  onUploadChange() {
    return this.notifyInput()
  }

  @Emit("input")
  notifyInput() {
    return this.uploadFiles
  }

  handleShowDeleteDialog() {
    this.showDeleteDialog = true
  }

  onUploadComplete() {
    this.reset()
    this.onUploadChange()
  }

  async deleteUploadedFile() {
    if (!this.value?.id) return

    this.deletingFile = true

    const result = await this.mutate<DeleteUploadFileMutationMutation>({
      mutation: DeleteUploadFileMutation,
      variables: {
        id: Number(this.value?.id),
      },
      done: () => {
        this.deletingFile = false
      },
      refetchQueries: this.refetchQueries,
    })

    if (result.data && !result.data.deleteUploadFile.error) {
      this.addSuccess("Uploaded File deleted successfully")
      this.showDeleteDialog = false
    }
  }

  reset() {
    this.showUploadModal = false
    this.$refs.dropzone && (this.$refs.dropzone as any).reset()
  }
}
