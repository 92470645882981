
import { EmploymentStatuses, JobFunctions } from "@/constants"
import { Vue, Prop, Component, Emit } from "vue-property-decorator"
import EditProfile from "@/views/consultant/EditProfile.vue"
import UserAvatar from "@/components/profile/UserAvatar.vue"

@Component({
  components: {
    EditProfile,
    UserAvatar,
  },
})
export default class ProfileHeaderInfo extends Vue {
  @Prop() readonly consultant!: any
  @Prop() readonly user!: any
  @Prop() readonly editable!: boolean
  @Prop({ default: false }) readonly flat!: boolean
  @Prop({ default: false }) readonly hideDetails!: boolean
  @Prop({ default: true }) readonly showSideInfo!: boolean

  showEditModal = false

  getEmploymentStatus(value: string) {
    return EmploymentStatuses.find((status) => status.value === value)?.text
  }
  getJobFunctions(values: string[]) {
    return JobFunctions.filter((status) => values.includes(status.value)).map((func) => func.text)
  }

  getInitials(name: string) {
    const names = name.split(" ")
    const initials = names.map((n) => n.charAt(0)).join("")
    return initials.toUpperCase()
  }

  @Emit("save")
  onSave() {
    this.showEditModal = false
    return true
  }

  getApplicantInfo() {
    return {
      consultant: {},
    }
  }

  onEditModalClose() {
    this.$emit("update:editMode", false)
  }
}
